/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Keep old CSP in production for now, with a report-only CSP in the headers config, so we can monitor one policy while enforcing another policy.
 */

const contentSecurityPolicy_img_src = [
  '*.ctfassets.net',
  '*.ads.linkedin.com',
  '*.google-analytics.com',
  '*.googletagmanager.com',
  '*.analytics.google.com',
  '*.g.doubleclick.net',
  'ade.googlesyndication.com',
  'www.google.com',
  'www.google.be',
  'www.facebook.com',
  'www.gstatic.com',
  'maps.gstatic.com',
  'maps.googleapis.com',
  '*.klaviyo.com',
  '*.intercomcdn.com',
  '*.intercomassets.com',
  '*.intercomusercontent.com',
  '*.intercom-attachments-1.com',
  '*.intercom-attachments-2.com',
  '*.intercom-attachments-3.com',
  '*.intercom-attachments-4.com',
  '*.intercom-attachments-5.com',
  '*.intercom-attachments-6.com',
  '*.intercom-attachments-7.com',
  '*.intercom-attachments-8.com',
  '*.intercom-attachments-9.com',
  '*.ggpht.com',
  'optimize.google.com',
  '*.bing.com',
  '*.microsoft.com',
  '*.clarity.ms',
  '*.survicate.com',
  '*.cloudfront.net',
  '*.typeform.com',
  'https://vercel.live', // vercel toolbar
  'https://vercel.com', // vercel toolbar
  'imgsct.cookiebot.com',
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
  'https://assets.survicate.com',
  'https://img.survicate.com',
  'https://images.unsplash.com', // survicate
];

const contentSecurityPolicy_connect_and_script_src = [
  '*.sentry.io',
  '*.contentful.com',
  '*.cookiebot.com',
  'vitals.vercel-insights.com', // Vercel analytics
  '*.analytics.google.com',
  '*.google-analytics.com',
  '*.googleanalytics.com',
  '*.googletagmanager.com',
  '*.g.doubleclick.net',
  'tagmanager.google.com',
  'connect.facebook.net',
  'www.facebook.com',
  '*.myshopify.com',
  '*.segment.com',
  '*.segmentapis.com',
  'ipapi.co',
  'maps.googleapis.com',
  'cognito-idp.eu-west-1.amazonaws.com',
  'cognito-identity.eu-west-1.amazonaws.com/',
  'https://logs.eu-west-1.amazonaws.com/',
  'https://store729833ce-729833ce-test.auth.eu-west-1.amazoncognito.com/oauth2/token',
  '*.trustpilot.com',
  '*.loyaltylion.net',
  '*.loyaltylion.com',
  '*.klaviyo.com',
  '*.intercom.io',
  '*.intercomcdn.com',
  '*.intercomcdn.eu',
  '*.intercomusercontent.com',
  'wss://nexus-websocket-a.intercom.io',
  'wss://nexus-websocket-b.intercom.io',
  ...(process.env.VERCEL_ENV === 'development'
    ? ['http://localhost:3002', 'http://localhost:3001', 'ws://localhost:3000']
    : []),
  '*.edgardcooper.com',
  '*.googleapis.com',
  'optimize.google.com',
  'www.googleoptimize.com',
  'www.youtube.com',
  '*.bing.com',
  'wss://*.bing.com',
  'www.googleadservices.com',
  'www.google.com',
  '*.vercel.app',
  'polyfill.io',
  'https://www.clarity.ms',
  '*.clarity.ms',
  '*.azurewebsites.net',
  '*.survicate.com',
  '*.datadome.co',
  '*.typeform.com',
  'apim-b2c-edgardcooper-prd.azure-api.net',
  '*.googlesyndication.com',
  'analytics.tiktok.com',
  '*.realytics.io',
  '*.realytics.net',
  'https://vercel.live', // vercel toolbar
  'wss://ws-us3.pusher.com', // vercel toolbar
  'va.vercel-scripts.com',
  '*.dwin1.com', // Awin tracking
  'https://survey.survicate.com',
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
  'https://survey-prd.survicate-cdn.com',
  'https://respondent.survicate.com',
];

const contentSecurityPolicy_form_action = [
  'intercom.help',
  'api-iam.intercom.io',
  'connect.facebook.net',
  '*.facebook.com',
  '*.typeform.com',
];

const contentSecurityPolicy_font_src = [
  'fonts.gstatic.com',
  '*.intercomcdn.com',
  'cdn.edgardcooper.com',
  '*.survicate.com',
  '*.typeform.com',
  'https://vercel.live', // vercel toolbar
  'https://assets.vercel.com', // vercel toolbar
  '*.klaviyo.com',
  '*.typekit.net', // klaviyo
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
];

const contentSecurityPolicy_style_src = [
  'fonts.googleapis.com',
  'tagmanager.google.com',
  'sdk.loyaltylion.net',
  'sdk-static.loyaltylion.net',
  'optimize.google.com',
  '*.bing.com',
  '*.klaviyo.com',
  '*.typekit.net', // klaviyo
  '*.survicate.com',
  '*.typeform.com',
  'https://vercel.live', // vercel toolbar,
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
];

const contentSecurityPolicy_media_src = ['js.intercomcdn.com', '*.ctfassets.net', '*.typeform.com'];

const contentSecurityPolicy_frame_src = [
  // 'https://*.cookiebot.com', // Unsure why it's needed to specify https when the wildcard version without protocol is already present
  // 'http://*.cookiebot.com',
  '*.cookiebot.com',
  // 'https://*.trustpilot.com/', // Unsure why it's needed to specify https when the wildcard version without protocol is already present
  // 'http://*.trustpilot.com/',
  '*.trustpilot.com',
  'optimize.google.com',
  'sdx.microsoft.com',
  '*.doubleclick.net', // wildcard version instead of the bid.g. and td. separately
  // 'bid.g.doubleclick.net',
  // 'td.doubleclick.net',
  '*.facebook.com',
  'https://anchor.fm/',
  '*.survicate.com',
  '*.typeform.com',
  'https://intercom-sheets.com',
  'https://vercel.live', // vercel toolbar
];

// const contentSecurityPolicy_child_src = [
//   'https://intercom-sheets.com',
//   'https://www.intercom-reporting.com',
//   'https://www.youtube.com',
//   'https://player.vimeo.com',
//   'https://fast.wistia.net',
// ];

// https://csp.withgoogle.com/docs/strict-csp.html
const contentSecurityPolicy = [
  "default-src 'none';",
  "base-uri 'self';",
  // eslint-disable-next-line max-len
  `script-src 'self' 'unsafe-inline' 'unsafe-eval' *.edgardcooper.com  ${contentSecurityPolicy_connect_and_script_src.join(
    ' ',
  )};`,
  `connect-src 'self' *.edgardcooper.com ${contentSecurityPolicy_connect_and_script_src.join(
    ' ',
  )};`,
  `font-src 'self' data: ${contentSecurityPolicy_font_src.join(' ')};`,
  `form-action 'self' ${contentSecurityPolicy_form_action.join(' ')};`,
  `frame-src 'self' ${contentSecurityPolicy_frame_src.join(' ')};`,
  // `child-src 'self' ${contentSecurityPolicy_child_src.join(' ')};`,
  // 'unsafe-inline' is needed for cookiebot cookiecontrol dialog :'( and (vercel toolbar)
  `style-src 'self' 'unsafe-inline' ${contentSecurityPolicy_style_src.join(' ')};`,
  `img-src 'self' data: blob: *.edgardcooper.com ${contentSecurityPolicy_img_src.join(' ')};`,
  "object-src 'none';",
  `media-src 'self' *.edgardcooper.com ${contentSecurityPolicy_media_src.join(' ')};`,
  // "prefetch-src 'self';", // 20240304 - put in comment as browser mention it's not recognized (deprectated)
].join(' ');

export default contentSecurityPolicy;
