import { createContext, useContext, useCallback } from 'react';

import { gql } from 'graphql-request';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';

import { ComponentGenericListCollection, Query } from 'services/contentful/generated-types';
import contentfulGraphQLRequest from 'services/contentful/graphql-request';

export enum ImageSetKeys {
  Cart = 'cart',
  Subscription = 'subscription',
  Product = 'product',
  Account = 'account',
  AccountLoyalty = 'account.loyalty',
  Logos = 'logos',
  PageHome2024 = 'page.home.2024',
  PageHomeMasterBrand = 'page.home.masterBrand',
  PageDogs = 'page.dogs',
  PageCats = 'page.cats',
  PageOurFood = 'page.ourFood',
  PageOurStory = 'page.ourStory',
  PageOurPlanet = 'page.ourPlanet',
  PageAluminiumCupsTins = 'page.aluminiumCupsTins',
  PagePlantBased = 'page.plantBased',
  PageCharity = 'page.charity',
  PageLoyalty = 'page.loyalty',
  PageStoreLocator = 'page.storeLocator',
  PageBlogIndex = 'page.blog.index',
  PageBabalu = 'page.babalu',
  ContentAboutEG = 'content.aboutEG',
  ContentWhyFresh = 'content.whyFresh',
  ContentFreshVersusTraditional = 'content.freshVersusTraditional',
  ContentRecyclingBins = 'content.recyclingBins',
  ContentLoyaltyHowItWorks = 'content.loyaltyHowItWorks',
  ContentLoyaltyChoices = 'content.loyaltyChoices',
  ContentFrequentlyAskedQuestions = 'content.frequentlyAskedQuestions',
  ContentProductClaims = 'content.productClaims',
  ContentProductQuizActivation = 'content.productQuizActivation',
  ExperimentProductIngredients = 'product.kibbleFrenchFrance',
  ProductQuiz = 'productQuiz',
  TreatsSlider = 'treatsSlider',
  TreatsGeneral = 'treatsGeneral',
  PageLandingRealFood = 'page.landing.realFood',
  PageLandingForDogsCats = 'page.landing.forDogsCats',
  PageLandingNaturallyNutritiousDogCat = 'page.landing.naturallyNutritiousDogCat',
  ContentNewsletter = 'content.newsletter',
  ContentBenefits = 'content.benefits',
  ContentBestsellers = 'content.bestsellers',
  ContentCharity = 'content.charity',
  ContentInfluencer = 'content.influencer',
  PageLandingBCorp = 'page.landing.bcorp',
  PageLandingCarbonScores = 'page.landing.carbonScores',
  PageLandingMGM = 'page.landing.mgm',
  PageLandingSignUp = 'page.landing.signUp',
  PageLandingZeroPawprintPlan = 'page.landing.zeroPawprintPlan',
  ContentRealIngredients = 'content.realIngredients',
  ContentCompareIngredients = 'content.compareIngredients',
  ContentAsSeenOn = 'content.asSeenOn',
}

/**
 * Query to BFF
 */
export interface ImagesData {
  imageSets: ImageSet[];
}

type ImageSet = {
  images: Array<ImageWithKey>;
  key: string;
};

type ImageWithKey = {
  alt?: string;
  key: string;
  src: string;
};

type Image = {
  alt?: string;
  src: string;
};

const query = gql`
  query imageSets($keys: [String!]!, $locale: String!, $preview: Boolean!) {
    componentGenericListCollection(
      locale: $locale
      where: { key_in: $keys }
      limit: 20
      preview: $preview
    ) {
      items {
        key
        valuesCollection(limit: 200) {
          items {
            ... on WrapperExternalDam {
              key
              image {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;

type Data = Query & {
  componentGenericListCollection: ComponentGenericListCollection;
};

export const getImageSets = async (
  variables: {
    locale: string;
    keys: string[];
  },
  ctx?: GetServerSidePropsContext | GetStaticPropsContext,
): Promise<ImagesData> =>
  contentfulGraphQLRequest<Data>(query, {
    locale: variables.locale,
    keys: variables.keys.map((key) => `images.${key}`),
    preview: !!ctx.preview,
  }).then((data) => ({
    imageSets: data.componentGenericListCollection.items.map((item) => ({
      key: item.key?.replace('images.', ''),
      images: item.valuesCollection.items
        .map((value) => {
          if ('key' in value && 'image' in value) {
            return {
              key: value.key,
              src: value.image?.url,
              alt: value.image?.title,
            };
          }
          return null;
        })
        .filter(Boolean),
    })),
  }));

/**
 * Provider
 */

type UseImagesReturn = {
  img: (key: string) => Image;
};

type ImagesProviderContext = {
  images: ImagesData;
};

type ImagesProviderProps = {
  images: ImagesData;
  children: React.ReactNode;
};

const Context = createContext<ImagesProviderContext>(null);

/**
 * Hook which gives a function which lets you query any prefetched image.
 * A function `img` is returned which you can then use to get an image by its key from the set.
 * If `optionalSetKey` is passed, img is called as `img(<imageKey>)`
 * Otherwise you need to prefix with the set key for the image: `img(<setKey>:<imageKey>)`
 */
export const useImages = (optionalSetKey?: string): UseImagesReturn => {
  const { images } = useContext(Context);

  const img = useCallback(
    (key: string): Image => {
      let imageKey;
      let setKey;

      const keyParts = key?.split(':');
      if (keyParts?.length !== 2 && !optionalSetKey) {
        throw Error(
          // eslint-disable-next-line max-len
          'When useImages is called without any arguments, `img` must be called with a setKey: `img(<setKey>:<imageKey>)`',
        );
      }

      if (keyParts?.length === 1) {
        [setKey, imageKey] = [optionalSetKey, key];
      } else if (keyParts?.length === 2) {
        [setKey, imageKey] = keyParts;
      }

      const imageSet = images?.imageSets?.find((set) => set.key === setKey);

      return imageSet?.images.find((image) => image.key === imageKey) as unknown as Image;
    },
    [optionalSetKey, images?.imageSets],
  );

  return { img };
};

const ImagesProvider = ({ images, children }: ImagesProviderProps): JSX.Element => (
  <Context.Provider
    value={{
      images,
    }}
  >
    {children}
  </Context.Provider>
);

export default ImagesProvider;
