import React, { FC, useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import { QueryClientProvider, QueryClient, Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Analytics } from '@vercel/analytics/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SpeedInsights } from '@vercel/speed-insights/next';
import { Resource } from 'i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

import '../styles/globals.css';
import { ProductList, Seo } from 'api/generated-types';
import RequiredLocaleSelector from 'components/pages/_app/RequiredLocaleSelector';
import PageSeo from 'components/seo/PageSeo';
import { SlideOverWrapper, ModalWrapper } from 'components/ui';
import AlertModalProvider from 'context/AlertModalProvider';
import { AwinProvider } from 'context/AwinProvider';
import Compose, { providerEntry } from 'context/Compose';
import CountryContextProvider from 'context/CountryContextProvider';
import ExperimentProvider from 'context/ExperimentProvider';
import { HeaderActionsProvider } from 'context/HeaderActionsProvider';
import ImagesProvider, { ImagesData } from 'context/ImagesProvider';
import IntercomProvider from 'context/IntercomProvider';
import LoyaltyLionProvider from 'context/LoyaltyLionProvider';
import ModalProvider from 'context/ModalProvider';
import OptimizeExperimentProvider from 'context/OptimizeExperimentProvider';
import ShopifyCartProvider from 'context/ShopifyCartProvider';
import ShopifyCustomerPrivacyProvider from 'context/ShopifyCustomerPrivacyProvider';
import SlideOverProvider from 'context/SlideOverProvider';
import SubscriptionProvider from 'context/SubscriptionProvider';
import SurvicateProvider from 'context/SurvicateProvider';
import ToastProvider from 'context/ToastProvider';
import { TrackingProvider } from 'context/TrackingProvider';
import configureAmplify from 'services/amplify/configure';
import { SessionContextProvider } from 'services/session';
import { getClientSideCookie, setClientSideCookie } from 'services/session/cookies';
import TranslationsProvider from 'services/translations/TranslationsProvider';
import { CookieNames } from 'utils/constants';
import contentSecurityPolicyLegacy from 'utils/content-security-policy/legacy';
import { mountNProgress } from 'utils/nProgress';
import createNextPage from 'utils/pages/createNextPage';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'nprogress/nprogress.css';
import '../styles/nProgress.css';

configureAmplify();

// Replaced default Component type with custom PageComponent
type ECAppProps<Props> = Omit<AppProps<Props>, 'Component'> & {
  // eslint-disable-next-line prettier/prettier
  Component: ReturnType<typeof createNextPage<Props>>;
};

const MyApp: FC<
  ECAppProps<{
    resources: Resource;
    images: ImagesData;
    seo: Seo;
    dehydratedState: unknown;
    translatedSlugs?: {
      en: string;
      nl: string;
      fr: string;
      de: string;
      it: string;
      es: string;
    };
    countryCode?: string;
    treatsOverviewData?: { productList: ProductList };
  }>
> = ({ Component, pageProps }) => {
  const router = useRouter();
  const { locale } = useRouter();
  const queryClientRef = useRef(new QueryClient());
  const [showExitPreviewLink, setShowExitPreviewLink] = useState(false);

  useEffect(() => {
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse');
    });

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', (event) => {
      if (event.key === 'Tab') {
        document.body.classList.remove('using-mouse');
      }
    });

    // YouTube-style loading bar to indicate page is loading
    mountNProgress();
  }, []);

  useEffect(() => {
    setClientSideCookie(CookieNames.PreferredLocale, router.locale);
  }, [router.locale]);

  useEffect(() => {
    setShowExitPreviewLink(!!getClientSideCookie(CookieNames.IsPreviewSession));
  }, []);

  const providersToCompose = [
    providerEntry(CountryContextProvider, { countryCode: pageProps.countryCode }),
    providerEntry(TranslationsProvider, { resources: pageProps.resources }),
    providerEntry(ImagesProvider, { images: pageProps.images }),
    // // UI Components
    providerEntry(SlideOverProvider),
    providerEntry(ModalProvider),
    providerEntry(HeaderActionsProvider),
    providerEntry(ToastProvider),
    providerEntry(AlertModalProvider),
    // // Business logic
    providerEntry(TrackingProvider),
    providerEntry(AwinProvider),
    providerEntry(ShopifyCustomerPrivacyProvider),
    providerEntry(ExperimentProvider),
    providerEntry(OptimizeExperimentProvider),
    providerEntry(SessionContextProvider),
    providerEntry(IntercomProvider),
    providerEntry(SurvicateProvider),
    providerEntry(LoyaltyLionProvider),
    providerEntry(ShopifyCartProvider),
    providerEntry(SubscriptionProvider),
  ];

  // set tags to send to Sentry
  useEffect(() => {
    Sentry.setTag('locale', locale);
  }, [locale]);



  return (
    <>
      <div>
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
          <link rel="icon" href="/favicon.ico" sizes="any" type="image/png" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
          <meta name="theme-color" content="#ffffff"/>
          <meta httpEquiv="Content-Security-Policy" content={contentSecurityPolicyLegacy} />
        </Head>
        <Script
          id="script-cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="e8b52806-9b4b-411e-a71d-f8e90667b067"
          // data-framework="IAB" // Awaiting apprval from Data
          type="text/javascript"
          data-culture={router.locale}
          strategy="beforeInteractive"
          async
        />


        <Script id="consent-google-analytics" data-cookieconsent="ignore">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "granted",
                wait_for_update: 500,
            });
            gtag("set", "ads_data_redaction", true);
            gtag("set", "url_passthrough", true);
          `}
        </Script>

        <PageSeo
          title={pageProps?.seo?.title}
          description={pageProps?.seo?.description}
          image={pageProps?.seo?.image}
        />
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={pageProps.dehydratedState}>
            <Compose providersToCompose={providersToCompose}>
              <Component.Layout
                className={Component.layoutClassName}
                translatedSlugs={pageProps.translatedSlugs}
                pageBannerOptions={Component.pageBannerOptions}
              >
                <Component {...pageProps} />
              </Component.Layout>
              <SlideOverWrapper />
              <ModalWrapper />
              <RequiredLocaleSelector translatedSlugs={pageProps.translatedSlugs} />
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
              {showExitPreviewLink && (
                // eslint-disable-next-line @next/next/no-html-link-for-pages
                <a
                  href="/api/exit-preview"
                  className="fixed z-50 bottom-0 left-1/2 transform -translate-x-1/2
                    text-red-400 bg-white px-4 py-2 border border-b-0"
                >
                  Exit preview mode
                </a>
              )}
            </Compose>
          </Hydrate>
        </QueryClientProvider>
        <Script
          async
          type="text/javascript"
          src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=LKSC83"
         />
        <Analytics />
        <SpeedInsights />
      </div>
    </>
  );
};

export default MyApp;
