import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { useSessionContext } from 'services/session';

import { useCountryContext } from './CountryContextProvider';

export enum SurveyKeys {
  'productRecommendationQuizDog' = 'productRecommendationQuizDog',
  'productRecommendationQuizCat' = 'productRecommendationQuizCat',
}

interface SurveyOptions {
  forceDisplay?: boolean;
  displayMethod?: 'immediately' | 'delayed' | 'exitIntent' | 'onScroll';
  displayOptions?: DisplayOptions;
}

interface DisplayOptions {
  delay?: number;
  scrolledPercentage?: number;
}

export interface SurveyProps {
  id: string;
  name: string;
  config?: {
    locales?: string[];
    countryCodes?: string[];
    sampling?: number;
  };
}

const defaultSurveyOptions: SurveyOptions = {
  forceDisplay: false,
  displayMethod: 'immediately',
};

const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
const surveyConfigs: { [key in SurveyKeys]: SurveyProps } = {
  [SurveyKeys.productRecommendationQuizDog]: {
    id: isProduction ? 'f12c45a586c4cba6' : 'f7a300ba9f55e20d',
    name: 'CSAT - Product Quiz Dog',
    config: {
      locales: [],
      countryCodes: [],
      sampling: 0.5,
    },
  },
  [SurveyKeys.productRecommendationQuizCat]: {
    id: isProduction ? 'c44ee3fc1ce865e2' : '92ba3d1c0396912d',
    name: 'CSAT - Product Quiz Cat',
    config: {
      locales: [],
      countryCodes: [],
      sampling: 0.5,
    },
  },
};

interface SurvicateContext {
  isLoaded: boolean;
  setVisitorTraits: (traits: unknown) => void;
  destroyVisitor: () => void;
  showSurvey: (survey: SurveyKeys, options?: SurveyOptions) => void;
}

const Context = createContext<SurvicateContext>(null);

export const useSurvicate = (): SurvicateContext => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useSurvicate must be used within a SurvicateProvider tag');
  }
  return contextState;
};

const SurvicateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { user } = useSessionContext();
  const router = useRouter();
  const { locale } = useRouter();
  const { countryCode } = useCountryContext();

  /**
   * Function that validates if the survey can be activated for the current countryCode and language
   */
  const validateSurveyConfig = useCallback(
    (survey: SurveyProps) => {
      const isValidSamplingResult = survey?.config?.sampling
        ? Math.random() < survey?.config?.sampling
        : true;

      if (
        (!survey?.config?.countryCodes ||
          survey?.config?.countryCodes.length === 0 ||
          survey?.config?.countryCodes.includes(countryCode)) &&
        (!survey?.config?.locales ||
          survey?.config?.locales.length === 0 ||
          survey?.config?.locales.includes(router.locale)) &&
        isValidSamplingResult
      ) {
        return true;
      }

      return false;
    },
    [router.locale, countryCode],
  );

  /**
   * Set traits to the current visitor
   */
  const setVisitorTraits = useCallback(
    (traits) => {
      if (isLoaded) {
        // eslint-disable-next-line no-underscore-dangle
        global._sva.setVisitorTraits(traits);
      }
    },
    [isLoaded],
  );

  /**
   * Destroy current visitor
   */
  const destroyVisitor = () => {
    if (isLoaded) {
      // eslint-disable-next-line no-underscore-dangle
      global._sva.destroyVisitor();
    }
  };

  /**
   * Activate a survey
   */
  const showSurvey = (surveyKey: SurveyKeys, options: SurveyOptions) => {
    const survey = surveyConfigs[surveyKey];
    const isSurveyValidated = validateSurveyConfig(survey);

    if (isLoaded && isSurveyValidated) {
      setTimeout(
        () => {
          // eslint-disable-next-line no-underscore-dangle
          global._sva.showSurvey(survey.id, defaultSurveyOptions);
        },
        options?.displayOptions?.delay ? options?.displayOptions?.delay * 1000 : 0,
      );
    }
  };

  /**
   * Wait for survicate to be loaded
   */
  useEffect(() => {
    const waitForSurvicate = () => {
      // eslint-disable-next-line no-underscore-dangle
      if (typeof global._sva !== 'undefined') {
        setIsLoaded(true);
      } else {
        setTimeout(waitForSurvicate, 250);
      }
    };
    waitForSurvicate();
  }, []);

  /**
   * Set visitor traits on load
   */
  useEffect(() => {
    if (isLoaded) {
      const traits = {
        language: locale,
        country_code: countryCode,
        anonymous_id: global.analytics?.user?.().anonymousId?.(),
        ...(user && { email: user?.email }),
      };

      setVisitorTraits(traits);
    }
  }, [isLoaded, user, locale, countryCode, setVisitorTraits]);

  return (
    <Context.Provider
      value={{
        isLoaded,
        setVisitorTraits,
        destroyVisitor,
        showSurvey,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SurvicateProvider;
