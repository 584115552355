import PetSurveyV2Input from 'components/parts/PetSurveyV2/PetSurveyV2Input';

export enum Keys {
  Dogs,
  Cats,
  Images,
  Product,
  Cart,
  Checkout,
  User,
  ActiveUser,
  Pets,
  PetFilters,
  ProductAttributes,
  PetSurveyV2,
  Sizes,
  StoreInfo,
  Subscription,
  SubscriptionMandate,
  SubscriptionThankYou,
  SubscriptionStatus,
  LoyaltyLion,
  PetOwner,
  ContentfulPetAge,
  Breeds,
  HealthFocusAreas,
}

type QueryKey = string[];

export const QueryKeys = {
  [Keys.Product]: (locale: string, countryCode: string, handle: string): QueryKey => [
    'product',
    handle,
    locale,
    countryCode,
  ],
  [Keys.Dogs]: (locale: string, countryCode: string): QueryKey => [
    'products',
    'dogs',
    locale,
    countryCode,
  ],
  [Keys.Cats]: (locale: string, countryCode: string): QueryKey => [
    'products',
    'cats',
    locale,
    countryCode,
  ],
  [Keys.User]: (): QueryKey => ['user'],
  [Keys.ActiveUser]: (email?: string): QueryKey => ['user', email].filter(Boolean),
  [Keys.Cart]: (countryCode: string, locale: string): QueryKey => ['cart', countryCode, locale],
  [Keys.Pets]: (email?: string): QueryKey => ['user', email, 'pets'],
  [Keys.PetFilters]: (): QueryKey => ['petFilter'],
  [Keys.ProductAttributes]: (locale: string, range?: 'dog' | 'cat'): QueryKey => [
    'productAttributes',
    range,
    locale,
  ],
  [Keys.PetSurveyV2]: (
    locale: string,
    countryCode: string,
    surveyInput: PetSurveyV2Input,
  ): QueryKey => [
    'petSurveyV2',
    countryCode,
    locale,
    surveyInput?.animal,
    surveyInput?.age,
    surveyInput?.bodyType,
    surveyInput?.foodType,
    surveyInput?.appetite,
    surveyInput?.treatType,
    surveyInput?.health.join(','),
    surveyInput?.grainFree,
    surveyInput?.allergies.join(','),
    surveyInput?.flavours.join(','),
    surveyInput?.wetFoodTexture,
  ],
  [Keys.Checkout]: (checkoutId: string): QueryKey => ['checkout', checkoutId],
  [Keys.Sizes]: (animal: string): QueryKey => ['petSizes', animal],
  [Keys.Subscription]: (locale: string, email: string): QueryKey => [
    'user',
    email,
    'subscription',
    locale,
  ],
  [Keys.SubscriptionMandate]: (locale: string, email: string): QueryKey => [
    'user',
    email,
    'subscriptionMandate',
    locale,
  ],
  [Keys.SubscriptionThankYou]: (locale: string): QueryKey => ['subscription-thank-you', locale],
  [Keys.SubscriptionStatus]: (cartId: string): QueryKey => ['subscriptionStatus', cartId],
  [Keys.LoyaltyLion]: (email: string, locale: string): QueryKey => [
    'user',
    'loyalty',
    email,
    locale,
  ],
  [Keys.PetOwner]: (email?: string) => ['user', email, 'petOwner'],
  [Keys.ContentfulPetAge]: (locale, ageKey: string) => ['contentful', 'petAge', locale, ageKey],
  [Keys.Breeds]: (locale: string, animalType: 'dog' | 'cat') => ['breeds', locale, animalType],
  [Keys.HealthFocusAreas]: (locale: string, range: 'dog' | 'cat') => [
    'healthFocusAreas',
    locale,
    range,
  ],
};
