import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import Script from 'next/script';

import analyticsWrapperThatWaitsForCookieConsent from 'services/analytics/analyticsWrapperThatWaitsForCookieConsent';
import { PageProtocol } from 'services/analytics/protocols';
import segmentSnippet from 'services/analytics/snippet';
import useTrackingEvents, { UseTrackingEventsResult } from 'services/analytics/useTrackingEvents';
import { setClientSideCookie } from 'services/session';
import { CookieNames } from 'utils/constants';
import { getParameterFromUrl } from 'utils/urlUtils';

import { useCountryContext } from './CountryContextProvider';

export interface TrackingContext {
  isAnalyticsLoaded: boolean;
  events: UseTrackingEventsResult;
}

const Context = createContext<TrackingContext>(null);

export const useTracking = (): TrackingContext => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useTracking must be used within a TrackingProvider tag');
  }
  return contextState;
};

export const TrackingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { locale } = useRouter();
  const { countryCode } = useCountryContext();
  const [isAnalyticsLoaded, setIsAnalyticLoaded] = useState(false);
  const {
    trackPageViewed,
    trackCartViewed,
    trackCustomerLoggedIn,
    trackProductListViewedFiltered,
    trackProductViewed,
    trackProductAddedRemoved,
    trackSubscriptionShipmentFrequencyUpdated,
    trackSubscriptionShipmentPreferredDeliveryDayUpdated,
    trackSubscriptionShipmentDateUpdated,
    trackStoreSearched,
    trackStoreClicked,
    trackReorder,
    trackExperimentViewed,
    trackExperimentEvent,
    trackCtaClicked,
    trackPageSectionViewed,
    trackSuggestedProductsShown,
    trackSuggestedProductClicked,
    trackFreeGiftViewed,
    trackProductQuizStarted,
    trackProductQuizRecommendationViewed,
    trackProductQuizStepCompleted,
    identifyUser,
  } = useTrackingEvents();
  const router = useRouter();

  useEffect(() => {
    // wait for segment to be loaded
    const waitForSegment = () => {
      if (typeof global.analytics !== 'undefined') {
        setIsAnalyticLoaded(true);
      } else {
        setTimeout(waitForSegment, 250);
      }
    };
    waitForSegment();
  }, []);

  const getPageProperties = (): PageProtocol => ({
    category: '',
    name: '',
    language: locale,
    country_code: countryCode || '',
    path: global.location?.pathname,
    referrer: global.document?.referrer,
    search: global.location?.search,
    title: global.document?.title,
    url: global.location?.href,
  });

  // Track page view on page load
  useEffect(() => {
    if (isAnalyticsLoaded) {
      // Wait until we have cookie consent preferences with Cookiebot before loading analytics config
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      try {
        analyticsWrapperThatWaitsForCookieConsent(global.analytics as any).load(
          process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        );
        trackPageViewed({ ...getPageProperties() });
      } catch (e) {
        // Ignore error that happens during country selection switch, package @segment/analytics-consent-tools is buggy (circular json error)
      }

      // Clear referrer to avoid ghost referral
      // Object.defineProperty(document, 'referrer', {
      //   value: null,
      // });

      /**
       * Set TTCLID
       */
      const ttclid = getParameterFromUrl('ttclid');
      if (ttclid) {
        setClientSideCookie(CookieNames.TikTokClickId, ttclid, { expires: 7 });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnalyticsLoaded, trackPageViewed]);

  // Track page views on route change
  useEffect(() => {
    const handleRouteChange = () => {
      trackPageViewed({ ...getPageProperties() });
    };

    // Wait for router.isReady so it doesn't trigger the initial load
    if (router.isReady) {
      router.events.on('routeChangeComplete', handleRouteChange);
    }
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <>
      {router.isReady && (
        <Script
          id="init-segment-snippet"
          async
          // Init Segment snippet
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: segmentSnippet(),
          }}
        />
      )}
      <Context.Provider
        value={{
          isAnalyticsLoaded,
          events: {
            trackPageViewed,
            trackCartViewed,
            trackProductViewed,
            trackProductListViewedFiltered,
            trackCustomerLoggedIn,
            trackProductAddedRemoved,
            trackSubscriptionShipmentFrequencyUpdated,
            trackSubscriptionShipmentPreferredDeliveryDayUpdated,
            trackSubscriptionShipmentDateUpdated,
            trackStoreClicked,
            trackStoreSearched,
            trackReorder,
            trackExperimentViewed,
            trackExperimentEvent,
            trackCtaClicked,
            trackPageSectionViewed,
            trackSuggestedProductsShown,
            trackSuggestedProductClicked,
            trackFreeGiftViewed,
            trackProductQuizStarted,
            trackProductQuizRecommendationViewed,
            trackProductQuizStepCompleted,
            identifyUser,
          },
        }}
      >
        {children}
      </Context.Provider>
    </>
  );
};
