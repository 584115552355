import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import Script from 'next/script';

import { getClientSideCookie } from 'services/session';
import { getCookieConsentCategoriesFromCookie } from 'services/session/cookies';
import { CookieNames } from 'utils/constants';

type AwinContext = Record<string, never>;

const Context = createContext<AwinContext>(null);

export const useAwin = (): AwinContext => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useAwin must be used within a AwinProvider tag');
  }
  return contextState;
};

/**
 * Provider that adds Awin tracking to the app
 * Awin is an affiliate marketing network
 * E&C contacts for more information: Laureen Mezger, Wouter Vanmaercke
 */
export const AwinProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isCookieConsentGiven, setIsCookieConsentGiven] = useState(false);

  useEffect(() => {
    const recurrentlyCheckCookieConsent = () => {
      setTimeout(() => {
        if (getClientSideCookie(CookieNames.CookieConsent) != null) {
          if (getCookieConsentCategoriesFromCookie().marketing) {
            setIsCookieConsentGiven(true);
          }
        } else {
          recurrentlyCheckCookieConsent();
        }
      }, 2000);
    };

    recurrentlyCheckCookieConsent();
  }, []);

  return (
    <>
      {isCookieConsentGiven && (
        <Script id="init-awin" async defer src="https://www.dwin1.com/100729.js" />
      )}
      <Context.Provider value={{}}>{children}</Context.Provider>
    </>
  );
};
