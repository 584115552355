import Link from 'next/link';

import { StickerBanner as StickerBannerType } from 'api/generated-types';

export interface Props {
  sticker: Omit<StickerBannerType, 'backgroundColor' | 'fontColor'>;
  outerClassName?: string;
  innerClassName?: string;
}

const Sticker = ({ sticker, outerClassName, innerClassName }: Props): JSX.Element => {
  const markup = (
    <div
      className={`rounded px-1.5 py-1 sm:px-2 text-xs sm:text-sm mb-1 font-rooney font-medium ${
        outerClassName || ''
      }`}
      style={{
        backgroundColor: sticker?.backgroundHexColor ? sticker?.backgroundHexColor : '#E1E000',
      }}
    >
      <span
        className={`${innerClassName || ''}`}
        style={{ color: sticker?.fontHexColor ? sticker?.fontHexColor : '#007377' }}
      >
        {sticker.displayText}
      </span>
    </div>
  );
  return sticker?.link ? (
    <Link
      href={sticker?.link ? sticker.link : ''}
      passHref
      className={sticker?.link ? 'cursor-pointer' : 'cursor-default'}
      target={sticker?.openLinkInNewTab ? '_blank' : '_self'}
    >
      {markup}
    </Link>
  ) : (
    markup
  );
};

export default Sticker;
