import { useCallback, useMemo } from 'react';

import { useRouter } from 'next/router';

import { Product, ProductVariant } from 'api/generated-types';
import PetSurveyV2Input from 'components/parts/PetSurveyV2/PetSurveyV2Input';
import type { ExtendedShopifyCart, useCart } from 'context/ShopifyCartProvider';
import { capitalize } from 'services/analytics/mapper/helper';
import {
  addPositionToProductList,
  mapProductFilterstoSegmentProtocol,
  mapProductsToSegmentProtocol,
  mapProductToSegmentProtocol,
} from 'services/analytics/mapper/mapProduct';
import { getClientSideCookie } from 'services/session';
import baseUrl from 'utils/baseUrl';
import { CookieNames } from 'utils/constants';
import { ProductFilterWithOptions } from 'utils/hooks/useProductFilters';
import { getParameterFromUrl } from 'utils/urlUtils';

import { mapEventPropertiesToProductQuizProtocol } from './mapper/mapProductQuiz';
import { PageProtocol, ProductListProtocol, ProductProtocol } from './protocols';
import { useCountryContext } from '../../context/CountryContextProvider';

export interface UseTrackingEventsResult {
  trackPageViewed: (pageProperties: PageProtocol) => Promise<void>;
  trackProductAddedRemoved: (
    action: 'add' | 'remove',
    category: 'cart' | 'subscription',
    id: string,
    productVariant: ProductVariant,
    quantity: number,
    type?: 'regular' | 'suggested',
  ) => Promise<void>;
  trackCartViewed: (
    cart: ExtendedShopifyCart,
    cartPreferences: ReturnType<typeof useCart>['cartPreferences'],
  ) => Promise<void>;
  trackProductViewed: (
    productVariant: ProductVariant,
    eventProperties: {
      view: 'Modal' | 'Page';
      recommendedProducts: Product[];
      isProductQuizRecommendation?: boolean;
    },
  ) => Promise<void>;
  trackProductListViewedFiltered: (
    action: 'Viewed' | 'Filtered',
    products: Product[],
    activeFilters: ProductFilterWithOptions[],
    eventProperties: {
      category: string;
    },
  ) => Promise<void>;
  trackCustomerLoggedIn: (attributes: {
    userId: string;
    cognitoId: string;
    email: string;
    emailVerified: boolean;
    provider: string;
    intercomUserHash?: string;
  }) => Promise<void>;
  trackSubscriptionShipmentFrequencyUpdated: (eventProperties: {
    subscriptionId: string;
    frequency: number;
  }) => Promise<void>;
  trackSubscriptionShipmentPreferredDeliveryDayUpdated: (eventProperties: {
    preferredDeliveryDay: number;
    subscriptionId: string;
  }) => Promise<void>;
  trackSubscriptionShipmentDateUpdated: (eventProperties: {
    subscriptionId: string;
    shipmentDate: string;
  }) => Promise<void>;
  trackStoreSearched: (eventProperties: {
    search: string;
    latitude: number;
    longitude: number;
  }) => Promise<void>;
  trackStoreClicked: (eventProperties: {
    storeId: string;
    title: string;
    street: string;
    number: string;
    city: string;
    postcode: string;
    latitude: number;
    longitude: number;
  }) => Promise<void>;
  trackReorder: (eventProperties: { orderName: string; clearCart: boolean }) => Promise<void>;
  trackExperimentViewed: (eventProperties: {
    experimentId: string;
    experimentName: string;
    variantId: string;
  }) => Promise<void>;
  trackExperimentEvent: (eventProperties: {
    experimentId: string;
    eventName: string;
    eventValue: string;
  }) => Promise<void>;
  trackCtaClicked: (eventProperties: { ctaId: string }) => Promise<void>;
  trackPageSectionViewed: (eventProperties: { pageSectionId: string }) => Promise<void>;
  trackSuggestedProductsShown: (eventProperties: {
    location: 'pdp' | 'cart';
    products: string[];
  }) => Promise<void>;
  trackSuggestedProductClicked: (eventProperties: {
    location: 'pdp' | 'cart';
    toProductUniversalKey: string;
    fromProductUniversalKey?: string;
  }) => Promise<void>;
  trackFreeGiftViewed: (
    productVariant: ProductVariant,
    eventProperties: {
      minValue: number;
      isValid: boolean;
    },
  ) => Promise<void>;
  trackProductQuizStarted: (eventProperties: { quizId: string; animal: string }) => Promise<void>;
  trackProductQuizRecommendationViewed: (
    surveyInput: PetSurveyV2Input,
    recommendedProducts: Product[],
    otherProducts: Product[],
    isEmptyresult: boolean,
  ) => Promise<void>;
  trackProductQuizStepCompleted: (
    surveyInput: PetSurveyV2Input,
    eventProperties: { stepName: string },
  ) => Promise<void>;
  identifyUser: (attributes: {
    userId: string;
    cognitoId: string;
    email: string;
    emailVerified: boolean;
    intercomUserHash?: string;
  }) => Promise<void>;
}

const useTrackingEvents = (): UseTrackingEventsResult => {
  const { locale } = useRouter();
  const { currencyCode, countryCode, cookieCountryCode } = useCountryContext();

  /**
   * Common event properties
   */
  const commonEventProperties = useMemo(
    () => ({
      language: locale,
      country_code: cookieCountryCode ?? countryCode?.toUpperCase() ?? '',
    }),
    [locale, countryCode, cookieCountryCode],
  );

  /**
   * Common context properties
   */
  const getCommonEventContext = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { language, country_code } = commonEventProperties;

    return {
      screen: {
        width: global?.innerWidth,
        height: global?.innerHeight,
        density: global?.devicePixelRatio,
      },
      language,
      country_code,
      ttclid: getParameterFromUrl('ttclid') || getClientSideCookie(CookieNames.TikTokClickId),
    };
  }, [commonEventProperties]);

  /*
   * Page
   */
  const trackPageViewed: UseTrackingEventsResult['trackPageViewed'] = useCallback(
    async (pageProperties): Promise<void> => {
      // Only send tracking event when country code is filled in
      if (commonEventProperties.country_code === '') return;

      global.analytics?.page(
        {
          ...pageProperties,
          ...commonEventProperties,
        },
        {
          context: getCommonEventContext(),
        },
      );
    },
    [commonEventProperties, getCommonEventContext],
  );

  /*
   * Product viewed
   */
  const trackProductViewed: UseTrackingEventsResult['trackProductViewed'] = useCallback(
    async (productVariant, eventProperties): Promise<void> => {
      const productProtocolled = mapProductToSegmentProtocol(productVariant, locale);
      const properties = {
        ...productProtocolled,
        ...commonEventProperties,
        suggested_products: eventProperties?.recommendedProducts.map((product, index) => ({
          universal_key: product.universalKey,
          position: index + 1,
        })),
        quantity: 1,
        view: eventProperties.view,
      };

      // Only send tracking event when country code is filled in
      if (commonEventProperties.country_code === '') return;

      global.analytics?.track('Product Viewed', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext, locale],
  );

  /*
   * Product list viewed / Product list filtered
   */
  const trackProductListViewedFiltered: UseTrackingEventsResult['trackProductListViewedFiltered'] =
    useCallback(
      async (action, products, activeFilters, eventProperties): Promise<void> => {
        // format products displayed on collection page for tracking

        // since collection page returns product with inner field variants and
        // we expect variant with inner field product, change up the element
        // so we can reuse the existing mapping code to segment product
        const productVariants = products?.map((product) => {
          const reformatedProductVariants = {
            ...product.variants[0],
            product: {
              ...product,
            },
          };
          reformatedProductVariants.product.variants = undefined;

          return reformatedProductVariants;
        });
        let productsProtocolled: ProductProtocol[] = mapProductsToSegmentProtocol(
          productVariants,
          locale,
          true,
        );
        productsProtocolled = addPositionToProductList(productsProtocolled);

        // create complete product list event
        const properties: ProductListProtocol = {
          ...commonEventProperties,
          list_id: eventProperties.category.toLowerCase(),
          ...mapProductFilterstoSegmentProtocol(activeFilters),
          // even though we see "cat/dog" more as "range" instead of "category",
          // the default ecommerce spec defines it as category
          category: capitalize(eventProperties.category),
          products: [...productsProtocolled],
          _clear: true,
        };

        // Only send tracking event when country code is filled in
        if (commonEventProperties.country_code === '') return;

        global.analytics?.track(`Product List ${action}`, properties, {
          context: getCommonEventContext(),
        });
      },
      [commonEventProperties, getCommonEventContext, locale],
    );

  const trackCartViewed: UseTrackingEventsResult['trackCartViewed'] = useCallback(
    async (
      cart: ExtendedShopifyCart,
      cartPreferences: ReturnType<typeof useCart>['cartPreferences'],
    ): Promise<void> => {
      const productVariantsInCart = cart.lines.edges.map(({ node }) => node.ecProductVariant);
      let productsProtocolled = mapProductsToSegmentProtocol(productVariantsInCart, locale);

      const getOriginalLineItem = (product: ProductProtocol) =>
        cart.lines.edges.find(
          ({ node }) =>
            product.shopify_variant_id.toString() ===
            node.ecProductVariant.identifiers.shopifyProductVariantId,
        );

      if (productsProtocolled?.length) {
        productsProtocolled = productsProtocolled.map((product, index) => ({
          ...product,
          position: index + 1,
          quantity: getOriginalLineItem(product)?.node.quantity,
          is_suggested: getOriginalLineItem(product)?.node.ecLineItemType === 'suggested',
        }));
      }

      const properties = {
        ...commonEventProperties,
        cart_id: cart.id,
        currency: cart.cost.totalAmount?.currencyCode || currencyCode,
        total: cart.cost.totalAmount?.amount || 0,
        value: cart.cost.totalAmount?.amount || 0,
        subscription: cartPreferences?.isSubscription,
        subscription_frequency: cartPreferences?.subscriptionFrequency,
        products: productsProtocolled,
        _clear: true,
      };

      // Only send tracking event when country code is filled in
      if (commonEventProperties.country_code === '') return;

      global.analytics?.track('Cart Viewed', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext, locale, currencyCode],
  );

  const trackProductAddedRemoved: UseTrackingEventsResult['trackProductAddedRemoved'] = useCallback(
    async (action, category, id, productVariant, quantity, type): Promise<void> => {
      const productProtocolled = mapProductToSegmentProtocol(productVariant, locale);
      // Add & Remove is always on 1 product
      const idProperty = category === 'cart' ? 'cart_id' : 'subscription_id';
      const properties = {
        ...productProtocolled,
        ...commonEventProperties,
        [idProperty]: id,
        quantity,
        is_suggested: type === 'suggested',
      };
      const actionName = action === 'add' ? 'Added' : 'Removed';
      const eventPrefix = category === 'subscription' ? 'Subscription ' : '';
      global.analytics?.track(`${eventPrefix}Product ${actionName}`, properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext, locale],
  );

  const identifyUser: UseTrackingEventsResult['identifyUser'] = useCallback(
    async (attributes) => {
      const shopifyUserId = attributes.userId;
      global.analytics?.identify(
        shopifyUserId,
        {
          ...commonEventProperties,
          email: attributes.email,
          email_verified: attributes?.emailVerified || false,
          cognito_id: attributes.cognitoId,
          shopify_id: shopifyUserId,
        },
        {
          integrations: {
            Intercom: {
              user_hash: attributes?.intercomUserHash,
            },
          },
        },
      );
    },
    [commonEventProperties],
  );
  /*
   * Track
   */
  const trackCustomerLoggedIn: UseTrackingEventsResult['trackCustomerLoggedIn'] = useCallback(
    async (attributes): Promise<void> => {
      const shopifyUserId = attributes.userId;
      if (shopifyUserId) {
        // Call alias to merge identities for Mixpanel destination
        global.analytics?.alias(shopifyUserId, global.analytics?.user?.().anonymousId?.());

        identifyUser({
          userId: shopifyUserId,
          cognitoId: attributes.cognitoId,
          email: attributes.email,
          emailVerified: attributes.emailVerified,
          intercomUserHash: attributes?.intercomUserHash,
        });

        global.analytics?.track(
          'Customer Logged In',
          {
            ...commonEventProperties,
            user_id: shopifyUserId,
            cognito_id: attributes.cognitoId,
            provider: attributes.provider,
          },
          {
            context: getCommonEventContext(),
          },
        );
      }
    },
    [commonEventProperties, getCommonEventContext, identifyUser],
  );

  // eslint-disable-next-line max-len
  const trackSubscriptionShipmentFrequencyUpdated: UseTrackingEventsResult['trackSubscriptionShipmentFrequencyUpdated'] =
    useCallback(
      async (eventProperties): Promise<void> => {
        const properties = {
          ...commonEventProperties,
          subscription_id: eventProperties.subscriptionId,
          frequency: eventProperties.frequency,
        };
        global.analytics?.track('Subscription Shipment Frequency Updated', properties, {
          context: getCommonEventContext(),
        });
      },
      [commonEventProperties, getCommonEventContext],
    );

  const trackSubscriptionShipmentPreferredDeliveryDayUpdated: UseTrackingEventsResult['trackSubscriptionShipmentPreferredDeliveryDayUpdated'] =
    useCallback(
      async (eventProperties): Promise<void> => {
        const properties = {
          ...commonEventProperties,
          subscription_id: eventProperties.subscriptionId,
          preferredDeliveryDay: eventProperties.preferredDeliveryDay,
        };
        global.analytics?.track(
          'Subscription Shipment Preferred Delivery Day Updated',
          properties,
          {
            context: getCommonEventContext(),
          },
        );
      },
      [commonEventProperties, getCommonEventContext],
    );

  const trackSubscriptionShipmentDateUpdated: UseTrackingEventsResult['trackSubscriptionShipmentDateUpdated'] =
    useCallback(
      async (eventProperties): Promise<void> => {
        const properties = {
          ...commonEventProperties,
          subscription_id: eventProperties.subscriptionId,
          shipment_date: eventProperties.shipmentDate,
        };
        global.analytics?.track('Subscription Shipment Date Updated', properties, {
          context: getCommonEventContext(),
        });
      },
      [commonEventProperties, getCommonEventContext],
    );

  const trackStoreSearched: UseTrackingEventsResult['trackStoreSearched'] = useCallback(
    async (eventProperties): Promise<void> => {
      const properties = {
        ...commonEventProperties,
        searched: eventProperties.search,
      };
      global.analytics?.track('Store Searched', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext],
  );

  const trackStoreClicked: UseTrackingEventsResult['trackStoreClicked'] = useCallback(
    async (eventProperties): Promise<void> => {
      const properties = {
        ...commonEventProperties,
        store_id: eventProperties.storeId,
        title: eventProperties.title || '',
        address1: eventProperties.street || '',
        address2: eventProperties.number || '',
        city: eventProperties.city || '',
        postal_code: eventProperties.postcode || '',
      };
      global.analytics?.track('Store Clicked', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext],
  );

  const trackReorder: UseTrackingEventsResult['trackReorder'] = useCallback(
    async (eventProperties): Promise<void> => {
      const properties = {
        ...commonEventProperties,
        order_name: eventProperties.orderName,
        clear_cart: eventProperties.clearCart,
      };
      global.analytics?.track('Reorder', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext],
  );

  const trackExperimentViewed: UseTrackingEventsResult['trackExperimentViewed'] = useCallback(
    async (eventProperties): Promise<void> => {
      const properties = {
        ...commonEventProperties,
        experiment_id: eventProperties.experimentId,
        experiment_name: eventProperties.experimentName,
        variant_id: eventProperties.variantId,
      };

      // Only send tracking event when country code is filled in
      if (commonEventProperties.country_code === '') return;

      global.analytics?.track('Experiment Viewed', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext],
  );

  const trackExperimentEvent: UseTrackingEventsResult['trackExperimentEvent'] = useCallback(
    async (eventProperties): Promise<void> => {
      const properties = {
        ...commonEventProperties,
        experiment_id: eventProperties.experimentId,
        event_name: eventProperties.eventName,
        event_value: eventProperties.eventValue,
      };
      global.analytics?.track('Experiment Event', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext],
  );

  const trackCtaClicked: UseTrackingEventsResult['trackCtaClicked'] = useCallback(
    async (eventProperties): Promise<void> => {
      const properties = {
        ...commonEventProperties,
        cta_id: eventProperties.ctaId,
      };
      global.analytics?.track('CTA Clicked', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext],
  );

  const trackPageSectionViewed: UseTrackingEventsResult['trackPageSectionViewed'] = useCallback(
    async (eventProperties): Promise<void> => {
      const properties = {
        ...commonEventProperties,
        page_section_id: eventProperties.pageSectionId,
      };

      global.analytics?.track('Page Section Viewed', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext],
  );

  /**
   * Suggested Product Shown
   */
  const trackSuggestedProductsShown: UseTrackingEventsResult['trackSuggestedProductsShown'] =
    useCallback(
      async (eventProperties): Promise<void> => {
        const productsMapped = eventProperties.products.map((product, index) => ({
          universal_key: product,
          position: index + 1,
        }));

        const properties = {
          products: productsMapped,
          location: eventProperties.location,
          ...commonEventProperties,
        };

        global.analytics?.track('Suggested Products Shown', properties, {
          context: getCommonEventContext(),
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [commonEventProperties, getCommonEventContext, locale],
    );

  /**
   * Suggested Product Clicked
   */
  const trackSuggestedProductClicked: UseTrackingEventsResult['trackSuggestedProductClicked'] =
    useCallback(
      async (eventProperties): Promise<void> => {
        const properties = {
          location: eventProperties.location,
          from_product_universal_key: eventProperties.fromProductUniversalKey,
          to_product_universal_key: eventProperties.toProductUniversalKey,
          ...commonEventProperties,
        };

        global.analytics?.track('Suggested Product Clicked', properties, {
          context: getCommonEventContext(),
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [commonEventProperties, getCommonEventContext, locale],
    );

  /**
   * Free Gift Viewed
   */
  const trackFreeGiftViewed: UseTrackingEventsResult['trackFreeGiftViewed'] = useCallback(
    async (productVariant, eventProperties): Promise<void> => {
      const productProtocolled = mapProductToSegmentProtocol(productVariant, locale);
      const properties = {
        ...productProtocolled,
        ...commonEventProperties,
        quantity: 1,
        gift_min_value: eventProperties.minValue,
        gift_is_valid: eventProperties.isValid,
      };

      global.analytics?.track('Free Gift Viewed', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext, locale],
  );

  /**
   * Product Quiz Started
   */
  const trackProductQuizStarted: UseTrackingEventsResult['trackProductQuizStarted'] = useCallback(
    async (eventProperties): Promise<void> => {
      const properties = {
        ...commonEventProperties,
        animal: eventProperties?.animal,
        quiz_id: eventProperties?.quizId,
      };

      global.analytics?.track('Product Quiz Started', properties, {
        context: getCommonEventContext(),
      });
    },
    [commonEventProperties, getCommonEventContext],
  );

  /**
   * Product Quiz Recommendation Viewed
   */
  const trackProductQuizRecommendationViewed: UseTrackingEventsResult['trackProductQuizRecommendationViewed'] =
    useCallback(
      async (surveyInput, recommendedProducts, otherProducts, isEmptyresult): Promise<void> => {
        const mapProductQuizProducts = (products: Product[]) =>
          products.map((product, index) => ({
            universal_key: product.universalKey,
            health_benefits: product.masterData.healthFocusAreas.map((hfa) => hfa.key),
            product_url: [baseUrl, locale, 'products', product?.content?.handle].join('/'),
            image_url: `https:${product?.content?.featuredImage?.src}`,
            position: index + 1,
          }));

        const properties = {
          ...commonEventProperties,
          ...mapEventPropertiesToProductQuizProtocol(surveyInput),
          is_empty_result: isEmptyresult,
          // Products
          recommended_products: mapProductQuizProducts(recommendedProducts),
          other_products: mapProductQuizProducts(otherProducts),
        };

        global.analytics?.track('Product Quiz Recommendation Viewed', properties, {
          context: getCommonEventContext(),
        });
      },
      [commonEventProperties, getCommonEventContext, locale],
    );

  /**
   * Product Quiz Step Completed
   */
  const trackProductQuizStepCompleted: UseTrackingEventsResult['trackProductQuizStepCompleted'] =
    useCallback(
      async (surveyInput, eventProperties): Promise<void> => {
        const properties = {
          ...commonEventProperties,
          step_name: eventProperties.stepName,
          ...mapEventPropertiesToProductQuizProtocol(surveyInput),
        };

        global.analytics?.track('Product Quiz Step Completed', properties, {
          context: getCommonEventContext(),
        });
      },
      [commonEventProperties, getCommonEventContext],
    );

  return {
    trackPageViewed,
    trackProductAddedRemoved,
    trackCartViewed,
    trackProductViewed,
    trackProductListViewedFiltered,
    trackCustomerLoggedIn,
    trackSubscriptionShipmentFrequencyUpdated,
    trackSubscriptionShipmentPreferredDeliveryDayUpdated,
    trackSubscriptionShipmentDateUpdated,
    trackStoreSearched,
    trackStoreClicked,
    trackReorder,
    trackExperimentViewed,
    trackExperimentEvent,
    trackCtaClicked,
    trackPageSectionViewed,
    trackSuggestedProductsShown,
    trackSuggestedProductClicked,
    trackFreeGiftViewed,
    trackProductQuizStarted,
    trackProductQuizRecommendationViewed,
    trackProductQuizStepCompleted,
    identifyUser,
  };
};

export default useTrackingEvents;
